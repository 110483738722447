<div *ngIf="cellData && params.context" class="sh-device-name-cell">
	<span class="sh-device-name-container">
		<sh-device-state-bar
			*ngIf="!params.hideStateBar"
			class="sh-device-state"
			[deviceState]="cellData.status"
		></sh-device-state-bar>
		<sh-device-image class="sh-device-image" [deviceModel]="cellData.model" [isVirtual]="cellData.isVirtual">
		</sh-device-image>
		<span class="device-name-text">{{ cellData.name }}</span>
	</span>
	@if (cellData.canIdentify) {
		<sh-id-button
			[disabled]="cellData.isDisabled"
			[isIdentifying]="cellData.isIdentifying"
			(toggleIdentify)="setIdentify(cellData.id, !!cellData.isIdentifying)"
		></sh-id-button>
	}
</div>
