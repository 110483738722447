import { FirmwarePackage } from '@shure/shared/models';

export enum FirmwareUpdateStage {
	/** The firmware update was canceled. */
	Canceled = 'CANCELED',
	/** The firmware update is complete. */
	Complete = 'COMPLETE',
	/** The device is being reserved for a firmware update. */
	DeviceReserved = 'DEVICE_RESERVED',
	/** An error occurred during the firmware update. */
	Error = 'ERROR',
	/** The device is available for a firmware update. */
	Idle = 'IDLE',
	/** The device is installing the firmware package. */
	Installing = 'INSTALLING',
	/** The request to update the device was received, but has not started yet. */
	Pending = 'PENDING',
	/** The firmware package is being validated by the device before installation. */
	PreInstallValidating = 'PRE_INSTALL_VALIDATING',
	/** The device is rebooting. */
	Rebooting = 'REBOOTING',
	/** The firmware package is being transferred to the device. */
	TransferringFiles = 'TRANSFERRING_FILES',
	/** The device is verifying that the firmware package was installed correctly. */
	Verifying = 'VERIFYING'
}

export enum FirmwareUpdateStatus {
	/** The device aborted the firmware update. */
	Aborted = 'ABORTED',
	/** The firmware update was canceled. */
	Canceled = 'CANCELED',
	/** There was a connectivity issue. */
	ConnectivityLoss = 'CONNECTIVITY_LOSS',
	/** The firmware package was corrupted locally or in transfer to the device. */
	CorruptPackage = 'CORRUPT_PACKAGE',
	/** The device is unavailable for a firmware update. */
	DeviceBusy = 'DEVICE_BUSY',
	/** The device cannot be found. */
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	DeviceRejectedPackage = 'DEVICE_REJECTED_PACKAGE',
	/** An error occurred while transferring the firmware package to the device. */
	FileTransferError = 'FILE_TRANSFER_ERROR',
	/** An error occurred while the device was installing the firmware package. */
	InstallationError = 'INSTALLATION_ERROR',
	/** The device does not support the specified firmware package. */
	InvalidPackage = 'INVALID_PACKAGE',
	/** The firmware update is complete. */
	Success = 'SUCCESS',
	/** An unknown error occurred. */
	UnknownError = 'UNKNOWN_ERROR',
	/** An error occurred while the device was verifying the installation. */
	VerificationError = 'VERIFICATION_ERROR'
}

export interface FirmwareUpdateProgress {
	package: FirmwarePackage;
	percentComplete: number;
	stage: FirmwareUpdateStage;
	status: FirmwareUpdateStatus;
}
