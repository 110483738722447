import { Component, computed, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval } from 'rxjs';

import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';

/**
 * ```<sh-carousel>``` is a list-like component used to display the images in carousel
 * in carousel.
 */
@Component({
	selector: 'sh-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
	@Input() public imageList: string[] = [];
	@Input() public isSelfRegisteredUser = true;
	public accountInformationSignal = this.organizationsStore.accountInformation;
	public currentImageIndex = 0;

	public firstName = computed(() => {
		return this.accountInformationSignal()?.firstName ?? '';
	});

	private carouselInterval$ = interval(4000);
	constructor(
		private readonly destroyRef: DestroyRef,
		private organizationsStore: OrganizationsStoreService
	) {}
	public async ngOnInit(): Promise<void> {
		this.carouselInterval$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.currentImageIndex = ++this.currentImageIndex % this.imageList.length;
		});
	}
}
