import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { APP_ENVIRONMENT, AppEnvironment, getShureCloudUri } from '@shure/cloud/shared/utils/config';

@Injectable({ providedIn: 'root' })
export class CloudNavigationService {
	constructor(
		private readonly router: Router,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {}

	/**
	 * Navigates to a URL in the Shure Cloud application, regardless of which application triggers the navigation.
	 * If the app provides a shureCloudUriMap in its environment, we assume that we are NOT in the Shure Cloud
	 * app, so we treat it as an external navigation using window.location.assign(). Otherwise, we treat it
	 * as an internal navigation using Angular's router.
	 * @param path the URL path to navigate to
	 */
	public toShureCloudUrl(path = ''): void {
		if (this.appEnv.shureCloudUriMap) {
			let baseUrl = getShureCloudUri(window.location.origin, this.appEnv.shureCloudUriMap);
			if (baseUrl?.endsWith('/')) {
				baseUrl = baseUrl.substring(0, baseUrl.length - 1);
			}

			window.open(`${baseUrl}${path}`, '_blank');
		} else {
			this.router.navigateByUrl(path);
		}
	}
}
