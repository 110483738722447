import { HttpResponse, HTTP_INTERCEPTORS, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import * as i0 from '@angular/core';
import { InjectionToken, VERSION, isDevMode, Injectable, Inject, Optional, NgModule } from '@angular/core';
import { scheduled, asapScheduler } from 'rxjs';
import { tap, finalize, shareReplay } from 'rxjs/operators';
class NgHttpCachingMemoryStorage {
  constructor() {
    this.store = new Map();
  }
  get size() {
    return this.store.size;
  }
  clear() {
    this.store.clear();
  }
  delete(key) {
    return this.store.delete(key);
  }
  forEach(callbackfn) {
    return this.store.forEach(callbackfn);
  }
  get(key) {
    return this.store.get(key);
  }
  has(key) {
    return this.store.has(key);
  }
  set(key, value) {
    this.store.set(key, value);
  }
}
const NG_HTTP_CACHING_CONFIG = new InjectionToken('ng-http-caching.config');
var NgHttpCachingStrategy;
(function (NgHttpCachingStrategy) {
  /**
   * All request are cacheable if HTTP method is into `allowedMethod`
   */
  NgHttpCachingStrategy["ALLOW_ALL"] = "ALLOW_ALL";
  /**
   * Only the request with `X-NG-HTTP-CACHING-ALLOW-CACHE` header are cacheable if HTTP method is into `allowedMethod`
   */
  NgHttpCachingStrategy["DISALLOW_ALL"] = "DISALLOW_ALL";
})(NgHttpCachingStrategy || (NgHttpCachingStrategy = {}));
var NgHttpCachingHeaders;
(function (NgHttpCachingHeaders) {
  /**
   * Request is cacheable if HTTP method is into `allowedMethod`
   */
  NgHttpCachingHeaders["ALLOW_CACHE"] = "X-NG-HTTP-CACHING-ALLOW-CACHE";
  /**
   * Request isn't cacheable
   */
  NgHttpCachingHeaders["DISALLOW_CACHE"] = "X-NG-HTTP-CACHING-DISALLOW-CACHE";
  /**
   * Specific cache lifetime for the request
   */
  NgHttpCachingHeaders["LIFETIME"] = "X-NG-HTTP-CACHING-LIFETIME";
  /**
   * You can tag multiple request by adding this header with the same tag and
   * using `NgHttpCachingService.clearCacheByTag(tag: string)` for delete all the tagged request
   */
  NgHttpCachingHeaders["TAG"] = "X-NG-HTTP-CACHING-TAG";
})(NgHttpCachingHeaders || (NgHttpCachingHeaders = {}));
const NgHttpCachingHeadersList = Object.values(NgHttpCachingHeaders);
const NG_HTTP_CACHING_SECOND_IN_MS = 1000;
const NG_HTTP_CACHING_MINUTE_IN_MS = NG_HTTP_CACHING_SECOND_IN_MS * 60;
const NG_HTTP_CACHING_HOUR_IN_MS = NG_HTTP_CACHING_MINUTE_IN_MS * 60;
const NG_HTTP_CACHING_DAY_IN_MS = NG_HTTP_CACHING_HOUR_IN_MS * 24;
const NG_HTTP_CACHING_WEEK_IN_MS = NG_HTTP_CACHING_DAY_IN_MS * 7;
const NG_HTTP_CACHING_MONTH_IN_MS = NG_HTTP_CACHING_DAY_IN_MS * 30;
const NG_HTTP_CACHING_YEAR_IN_MS = NG_HTTP_CACHING_DAY_IN_MS * 365;
const NgHttpCachingConfigDefault = {
  store: new NgHttpCachingMemoryStorage(),
  lifetime: NG_HTTP_CACHING_HOUR_IN_MS,
  version: VERSION.major,
  allowedMethod: ['GET', 'HEAD'],
  cacheStrategy: NgHttpCachingStrategy.ALLOW_ALL
};
class NgHttpCachingService {
  constructor(config) {
    this.queue = new Map();
    this.gcLock = false;
    this.devMode = isDevMode();
    if (config) {
      this.config = {
        ...NgHttpCachingConfigDefault,
        ...config
      };
    } else {
      this.config = {
        ...NgHttpCachingConfigDefault
      };
    }
    // start cache clean
    this.runGc();
  }
  /**
   * Return the config
   */
  getConfig() {
    return this.config;
  }
  /**
   * Return the queue map
   */
  getQueue() {
    return this.queue;
  }
  /**
   * Return the cache store
   */
  getStore() {
    return this.config.store;
  }
  /**
   * Return response from cache
   */
  getFromCache(req) {
    const key = this.getKey(req);
    const cached = this.config.store.get(key);
    if (!cached) {
      return undefined;
    }
    if (this.isExpired(cached)) {
      this.clearCacheByKey(key);
      return undefined;
    }
    return this.deepFreeze(cached.response);
  }
  /**
   * Add response to cache
   */
  addToCache(req, res) {
    const entry = {
      url: req.urlWithParams,
      response: res,
      request: req,
      addedTime: Date.now(),
      version: this.config.version
    };
    if (this.isValid(entry)) {
      const key = this.getKey(req);
      this.config.store.set(key, entry);
      return true;
    }
    return false;
  }
  /**
   * Delete response from cache
   */
  deleteFromCache(req) {
    const key = this.getKey(req);
    return this.clearCacheByKey(key);
  }
  /**
   * Clear the cache
   */
  clearCache() {
    this.config.store.clear();
  }
  /**
   * Clear the cache by key
   */
  clearCacheByKey(key) {
    return this.config.store.delete(key);
  }
  /**
   * Clear the cache by regex
   */
  clearCacheByRegex(regex) {
    let count = 0;
    this.config.store.forEach((_, key) => {
      if (regex.test(key)) {
        if (this.clearCacheByKey(key)) {
          count++;
        }
      }
    });
    return count;
  }
  /**
   * Clear the cache by TAG
   */
  clearCacheByTag(tag) {
    let count = 0;
    this.config.store.forEach((entry, key) => {
      const tagHeader = entry.request.headers.get(NgHttpCachingHeaders.TAG);
      if (tagHeader && tagHeader.split(',').includes(tag)) {
        if (this.clearCacheByKey(key)) {
          count++;
        }
      }
    });
    return count;
  }
  /**
   * Run garbage collector (delete expired cache entry)
   */
  runGc() {
    if (this.gcLock) {
      return false;
    }
    this.gcLock = true;
    this.config.store.forEach((entry, key) => {
      if (this.isExpired(entry)) {
        this.clearCacheByKey(key);
      }
    });
    this.gcLock = false;
    return true;
  }
  /**
   * Return true if cache entry is expired
   */
  isExpired(entry) {
    // if user provide custom method, use it
    if (typeof this.config.isExpired === 'function') {
      const result = this.config.isExpired(entry);
      // if result is undefined, normal behaviour is provided
      if (result !== undefined) {
        return result;
      }
    }
    // if version change, always expire
    if (this.config.version !== entry.version) {
      return true;
    }
    // config/default lifetime
    let lifetime = this.config.lifetime;
    // request has own lifetime
    const headerLifetime = entry.request.headers.get(NgHttpCachingHeaders.LIFETIME);
    if (headerLifetime) {
      lifetime = +headerLifetime;
    }
    // never expire if 0
    if (lifetime === 0) {
      return false;
    }
    // wrong lifetime
    if (lifetime < 0 || isNaN(lifetime)) {
      throw new Error('lifetime must be greater than or equal 0');
    }
    return entry.addedTime + lifetime < Date.now();
  }
  /**
   * Return true if cache entry is valid for store in the cache
   * Default behaviour is whether the status code falls in the 2xx range.
   */
  isValid(entry) {
    // if user provide custom method, use it
    if (typeof this.config.isValid === 'function') {
      const result = this.config.isValid(entry);
      // if result is undefined, normal behaviour is provided
      if (result !== undefined) {
        return result;
      }
    }
    // different version
    if (this.config.version !== entry.version) {
      return false;
    }
    return entry.response.ok;
  }
  /**
   * Return true if the request is cacheable
   */
  isCacheable(req) {
    // if user provide custom method, use it
    if (typeof this.config.isCacheable === 'function') {
      const result = this.config.isCacheable(req);
      // if result is undefined, normal behaviour is provided
      if (result !== undefined) {
        return result;
      }
    }
    // request has disallow cache header
    if (req.headers.has(NgHttpCachingHeaders.DISALLOW_CACHE)) {
      return false;
    }
    // strategy is disallow all...
    if (this.config.cacheStrategy === NgHttpCachingStrategy.DISALLOW_ALL) {
      // request isn't allowed if come without allow header
      if (!req.headers.has(NgHttpCachingHeaders.ALLOW_CACHE)) {
        return false;
      }
    }
    // if allowed method is only ALL, allow all http methos
    if (this.config.allowedMethod.length === 1) {
      if (this.config.allowedMethod[0] === 'ALL') {
        return true;
      }
    }
    // request is allowed if method is in allowedMethod
    return this.config.allowedMethod.indexOf(req.method) !== -1;
  }
  /**
   * Return the cache key.
   * Default key is http method plus url with query parameters, eg.:
   * `GET@https://github.com/nigrosimone/ng-http-caching`
   */
  getKey(req) {
    // if user provide custom method, use it
    if (typeof this.config.getKey === 'function') {
      const result = this.config.getKey(req);
      // if result is undefined, normal behaviour is provided
      if (result !== undefined) {
        return result;
      }
    }
    // default key is req.method plus url with query parameters
    return req.method + '@' + req.urlWithParams;
  }
  /**
   * Return observable from cache
   */
  getFromQueue(req) {
    const key = this.getKey(req);
    const cached = this.queue.get(key);
    if (!cached) {
      return undefined;
    }
    return cached;
  }
  /**
   * Add observable to cache
   */
  addToQueue(req, obs) {
    const key = this.getKey(req);
    this.queue.set(key, obs);
  }
  /**
   * Delete observable from cache
   */
  deleteFromQueue(req) {
    const key = this.getKey(req);
    return this.queue.delete(key);
  }
  /**
   * Recursively Object.freeze simple Javascript structures consisting of plain objects, arrays, and primitives.
   * Make the data immutable.
   * @returns immutable object
   */
  deepFreeze(object) {
    // No freezing in production (for better performance).
    if (!this.devMode || !object || typeof object !== 'object') {
      return object;
    }
    // When already frozen, we assume its children are frozen (for better performance).
    // This should be true if you always use `deepFreeze` to freeze objects.
    //
    // Note that Object.isFrozen will also return `true` for primitives (numbers,
    // strings, booleans, undefined, null), so there is no need to check for
    // those explicitly.
    if (Object.isFrozen(object)) {
      return object;
    }
    // At this point we know that we're dealing with either an array or plain object, so
    // just freeze it and recurse on its values.
    Object.freeze(object);
    Object.keys(object).forEach(key => this.deepFreeze(object[key]));
    return object;
  }
  static {
    this.ɵfac = function NgHttpCachingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgHttpCachingService)(i0.ɵɵinject(NG_HTTP_CACHING_CONFIG, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgHttpCachingService,
      factory: NgHttpCachingService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgHttpCachingService, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NG_HTTP_CACHING_CONFIG]
      }, {
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Fix for https://github.com/ReactiveX/rxjs/issues/7241
 */
function* _of(value) {
  yield value;
}
class NgHttpCachingInterceptorService {
  constructor(cacheService) {
    this.cacheService = cacheService;
  }
  intercept(req, next) {
    // run garbage collector
    this.cacheService.runGc();
    // Don't cache if it's not cacheable
    if (!this.cacheService.isCacheable(req)) {
      return this.sendRequest(req, next);
    }
    // Checked if there is pending response for this request
    const cachedObservable = this.cacheService.getFromQueue(req);
    if (cachedObservable) {
      // console.log('cachedObservable',cachedObservable);
      return cachedObservable;
    }
    // Checked if there is cached response for this request
    const cachedResponse = this.cacheService.getFromCache(req);
    if (cachedResponse) {
      // console.log('cachedResponse');
      return scheduled(_of(cachedResponse.clone()), asapScheduler);
    }
    // If the request of going through for first time
    // then let the request proceed and cache the response
    // console.log('sendRequest', req);
    const shared = this.sendRequest(req, next).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        this.cacheService.addToCache(req, event.clone());
      }
    }), finalize(() => {
      // delete pending request
      this.cacheService.deleteFromQueue(req);
    }), shareReplay());
    // add pending request to queue for cache parallell request
    this.cacheService.addToQueue(req, shared);
    return shared;
  }
  /**
   * Send http request (next handler)
   */
  sendRequest(req, next) {
    let cloned = req.clone();
    // trim custom headers before send request
    NgHttpCachingHeadersList.forEach(ngHttpCachingHeaders => {
      if (cloned.headers.has(ngHttpCachingHeaders)) {
        cloned = cloned.clone({
          headers: cloned.headers.delete(ngHttpCachingHeaders)
        });
      }
    });
    return next.handle(cloned);
  }
  static {
    this.ɵfac = function NgHttpCachingInterceptorService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgHttpCachingInterceptorService)(i0.ɵɵinject(NgHttpCachingService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgHttpCachingInterceptorService,
      factory: NgHttpCachingInterceptorService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgHttpCachingInterceptorService, [{
    type: Injectable
  }], function () {
    return [{
      type: NgHttpCachingService
    }];
  }, null);
})();
class NgHttpCachingModule {
  static forRoot(ngHttpCachingConfig) {
    return {
      ngModule: NgHttpCachingModule,
      providers: [{
        provide: NG_HTTP_CACHING_CONFIG,
        useValue: ngHttpCachingConfig
      }]
    };
  }
  static {
    this.ɵfac = function NgHttpCachingModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgHttpCachingModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgHttpCachingModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NgHttpCachingService, NgHttpCachingInterceptorService, {
        provide: HTTP_INTERCEPTORS,
        useClass: NgHttpCachingInterceptorService,
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgHttpCachingModule, [{
    type: NgModule,
    args: [{
      providers: [NgHttpCachingService, NgHttpCachingInterceptorService, {
        provide: HTTP_INTERCEPTORS,
        useClass: NgHttpCachingInterceptorService,
        multi: true
      }]
    }]
  }], null, null);
})();
const KEY_PREFIX = 'NgHttpCaching::';
const serializeRequest = req => {
  const request = req.clone(); // Make a clone, useful for doing destructive things
  return JSON.stringify({
    headers: Object.fromEntries(
    // Just a helper to make this into an object, not really required but makes the output nicer
    request.headers.keys().map(
    // Get all of the headers
    key => [key, request.headers.getAll(key)] // Get all of the corresponding values for the headers
    )),
    method: request.method,
    url: request.url,
    params: Object.fromEntries(
    // Just a helper to make this into an object, not really required but makes the output nicer
    request.headers.keys().map(
    // Get all of the headers
    key => [key, request.headers.getAll(key)] // Get all of the corresponding values for the headers
    )),
    withCredentials: request.withCredentials,
    respnseType: request.responseType,
    body: request.serializeBody() // Serialize the body, all well and good since we are working on a clone
  });
};
const serializeResponse = res => {
  const response = res.clone();
  return JSON.stringify({
    headers: Object.fromEntries(
    // Just a helper to make this into an object, not really required but makes the output nicer
    response.headers.keys().map(
    // Get all of the headers
    key => [key, response.headers.getAll(key)] // Get all of the corresponding values for the headers
    )),
    status: response.status,
    statusText: response.statusText,
    url: response.url,
    body: response.body // Serialize the body, all well and good since we are working on a clone
  });
};
const deserializeRequest = req => {
  const request = JSON.parse(req);
  const headers = new HttpHeaders(request.headers);
  const params = new HttpParams(); // Probably some way to make this a one-liner, but alas, there are no good docs
  for (const parameter in request.params) {
    request.params[parameter].forEach(paramValue => params.append(parameter, paramValue));
  }
  return new HttpRequest(request.method, request.url, request.body, {
    headers,
    params,
    responseType: request.responseType,
    withCredentials: request.withCredentials
  });
};
const deserializeResponse = res => {
  const response = JSON.parse(res);
  return new HttpResponse({
    url: response.url,
    headers: new HttpHeaders(response.headers),
    body: response.body,
    status: response.status,
    statusText: response.statusText
  });
};
class NgHttpCachingBrowserStorage {
  constructor(storage) {
    this.storage = storage;
  }
  get size() {
    let count = 0;
    for (let i = 0, e = this.storage.length; i < e; i++) {
      const key = this.storage.key(i);
      if (key && key.startsWith(KEY_PREFIX)) {
        count++;
      }
    }
    return count;
  }
  clear() {
    for (let i = 0, e = this.storage.length; i < e; i++) {
      const key = this.storage.key(i);
      if (key && key.startsWith(KEY_PREFIX)) {
        this.storage.removeItem(key);
      }
    }
  }
  delete(key) {
    this.storage.removeItem(key);
    return true;
  }
  forEach(callbackfn) {
    // iterate this.storage
    const lenPrefix = KEY_PREFIX.length;
    for (let i = 0, e = this.storage.length; i < e; i++) {
      const key = this.storage.key(i);
      if (key && key.startsWith(KEY_PREFIX)) {
        const value = this.get(key.substring(lenPrefix));
        if (value) {
          callbackfn(value, key);
        }
      }
    }
  }
  get(key) {
    const item = this.storage.getItem(KEY_PREFIX + key);
    if (item) {
      const parsedItem = JSON.parse(item);
      return {
        url: parsedItem.url,
        response: deserializeResponse(parsedItem.response),
        request: deserializeRequest(parsedItem.request),
        addedTime: parsedItem.addedTime,
        version: parsedItem.version
      };
    }
    return undefined;
  }
  has(key) {
    return this.storage.getItem(KEY_PREFIX + key) !== undefined;
  }
  set(key, value) {
    const unParsedItem = {
      url: value.url,
      response: serializeResponse(value.response),
      request: serializeRequest(value.request),
      addedTime: value.addedTime,
      version: value.version
    };
    this.storage.setItem(KEY_PREFIX + key, JSON.stringify(unParsedItem));
  }
}
class NgHttpCachingLocalStorage extends NgHttpCachingBrowserStorage {
  constructor() {
    super(localStorage);
  }
}
class NgHttpCachingSessionStorage extends NgHttpCachingBrowserStorage {
  constructor() {
    super(sessionStorage);
  }
}

/*
 * Public API Surface of ng-http-caching
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NG_HTTP_CACHING_CONFIG, NG_HTTP_CACHING_DAY_IN_MS, NG_HTTP_CACHING_HOUR_IN_MS, NG_HTTP_CACHING_MINUTE_IN_MS, NG_HTTP_CACHING_MONTH_IN_MS, NG_HTTP_CACHING_SECOND_IN_MS, NG_HTTP_CACHING_WEEK_IN_MS, NG_HTTP_CACHING_YEAR_IN_MS, NgHttpCachingBrowserStorage, NgHttpCachingConfigDefault, NgHttpCachingHeaders, NgHttpCachingHeadersList, NgHttpCachingInterceptorService, NgHttpCachingLocalStorage, NgHttpCachingMemoryStorage, NgHttpCachingModule, NgHttpCachingService, NgHttpCachingSessionStorage, NgHttpCachingStrategy, deserializeRequest, deserializeResponse, serializeRequest, serializeResponse };
