//TODO: This service will be removed once we implement CSB-569
import { DestroyRef, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, tap } from 'rxjs';

import {
	OrganizationsApiService,
	VerificationRequestDto,
	VerificationRequestResponse,
	StrictHttpResponse
} from '@shure/cloud/shared/request-business-account/data-access';
import {
	OrganizationsStoreService,
	VerificationStatus
} from '@shure/cloud/shared/services/organizations-store-service';

import { RequestBusinessAccountMainComponent } from '../request-business-account-main.component';

@Injectable({ providedIn: 'root' })
export class RequestBusinessAccountService {
	private readonly defaultDialogConfig: MatDialogConfig = {
		width: '400px'
	};
	public accountInformationSignal = this.organizationsStore.accountInformation;

	constructor(
		private readonly dialog: MatDialog,
		private organizationsStore: OrganizationsStoreService,
		public organizationsApiService: OrganizationsApiService,
		private readonly destroyRef: DestroyRef,
		private readonly translocoService: TranslocoService
	) {}

	/**
	 * Open a request form dialog
	 */
	public openRequestFormDialog(
		config: MatDialogConfig = this.defaultDialogConfig
	): Observable<VerificationRequestDto> {
		const organizationDetails = this.accountInformationSignal().organizationDetails;
		const dialogRef = this.dialog.open(RequestBusinessAccountMainComponent, {
			...this.defaultDialogConfig,
			...config,
			data: { ...organizationDetails }
		});

		return dialogRef.afterClosed();
	}

	/**
	 * Submits a verification request for a business account.
	 * Updates the organization's information and displays success or error messages.
	 *
	 * @param verificationRequest - VerificationRequestDto data representation for the verification request
	 */
	public submitVerificationRequest(
		verificationRequest: VerificationRequestDto
	): Observable<StrictHttpResponse<VerificationRequestResponse>> {
		const verificationRequestData = {
			companyName: verificationRequest.companyName.trim(),
			organizationName: verificationRequest.organizationName.trim()
		};
		return this.organizationsApiService.verificationRequest$Response({ body: { ...verificationRequestData } }).pipe(
			tap(() => {
				const organizationDetails = this.accountInformationSignal().organizationDetails;

				// Updates account info in store with verification status as "REQUESTED"
				this.organizationsStore.updateAccountInfo({
					organizationDetails: {
						...organizationDetails,
						...verificationRequestData,
						verificationStatus: VerificationStatus.REQUESTED
					},
					companyName: verificationRequestData.companyName
				});
			})
		);
	}
}
